<template>
  <div class="d-block w-100" v-if="!catalogLoaded && wocRunningState != 'END'">
    <div class="end-sale mb10">
      <div class="spinner-border spinner" role="status">
        <span class="visually-hidden spinner">Loading...</span>
      </div>
    </div>

    <div v-if="!socketConnected" class="alert alert-warning">
      {{ socketStep }}
    </div>
  </div>

  <div class="d-flex justify-content-center w-100" v-if="wocRunningState == 'END'">
    <div class="end-sale">
      <p v-if="!endOfSaleTexts[`text_fin_vente_${$i18n.locale}`]">
        {{ $t('sale_end.first') }}
        {{ catalogNames[`nom_${$i18n.locale}`] }} {{ $t('sale_end.final')
        }}<br />
        {{ $t('sale_end.greetings') }}
      </p>
      <p v-else>
        {{ endOfSaleTexts[`text_fin_vente_${$i18n.locale}`] }}
      </p>
    </div>
  </div>

  <div class="sales-area row">
    <div
      v-if="
        catalogLoaded &&
        catalogNames[`nom_${$i18n.locale}`] &&
        wocRunningState != 'END'
      "
    >
      <p class="saleName">{{ (catalogNames || {})[`nom_${$i18n.locale}`] }}</p>
      <hr />
      <VacPresentation />
      <div class="row" v-if="step !== 4">
        <div class="col-12">
          <div class="sales-area">
            <h1>
              <strong>{{ $t('sale_area.lot') }} {{ currentLot.lot }} — </strong>
              <span>{{ currentLot.appellation }}</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="trading-zone row" v-if="step !== 4">
        <div class="img-area col-xs-12 col-md-6 col-lg-4">
          <LotPicture />
        </div>
        <div class="price-area col-xs-12 col-md-6 col-lg-4">
          <PriceArea
            v-if="step === 3 && price !== false"
            :timer="timingPrice"
            :title="$t('price_area.timer_text_level')"
            :price="price"
          />
          <PriceArea
            v-if="step === 2"
            :timer="timingLot"
            :title="$t('price_area.timer_text_lot')"
          />
          <p class="alert-bids" v-if="absenteeBids > 0">
            <span>{{ $t(msgLotHasBisStart) }} </span> <br />
            <span>{{ $t(msgLotHasBisEnd) }} </span>
          </p>
          <PriceLanding
            v-if="step === 3 && currentLot.prices"
            :prices="currentLot.prices"
          />
          <Button
            v-if="step === 3 && !msgStatusLot && !isVisitor && isAllowedBuying"
            :msg="$t('sale_area.buy_button')"
            class="sub-area"
            @click="buyThis()"
          />
          <p v-if="msgStatusLot" class="unsold">{{ $t(msgStatusLot) }}</p>
        </div>
        <div class="lot-area col-xs-12 col-md-12 col-lg-4">
          <LotArea
            :lots="lots"
            :current-lot-id="currentLot.lot"
            :bids="bids"
          ></LotArea>
        </div>
      </div>
      <DetailsArea :lot="currentLot" v-if="step !== 4" />
    </div>
  </div>
</template>

<script>
import LotPicture from '@/components/LotPicture/LotPicture.vue';
import PriceArea from '@/components/PriceArea/PriceArea.vue';
import PriceLanding from '@/components/PriceLanding/PriceLanding.vue';
import LotArea from '@/components/LotArea/LotArea.vue';
import Button from '@/components/Button/Button.vue';
import DetailsArea from '@/components/DetailsArea/DetailsArea.vue';
import VacPresentation from '@/components/VacPresentation/VacPresentation.vue';

export default {
  name: 'Sales',
  components: {
    LotPicture,
    PriceArea,
    PriceLanding,
    LotArea,
    Button,
    DetailsArea,
    VacPresentation,
  },
  computed: {
    catalogLoaded() {
      return this.$store.state.catalogLoaded;
    },
    catalogId() {
      return this.$store.state.catalogId;
    },
    catalogNames() {
      return this.$store.state.catalogNames;
    },
    endOfSaleTexts() {
      return this.$store.state.endOfSaleTexts;
    },
    currentLot() {
      return this.$store.state.currentLot;
    },
    price() {
      return this.$store.state.price;
    },
    lots() {
      return this.$store.state.lots;
    },
    timingPrice() {
      return Math.max(this.$store.state.timingPrice.getSeconds() - 1, 0);
    },
    timingLot() {
      return Math.max(this.$store.state.timingLot.getSeconds() - 1, 0);
    },
    absenteeBids() {
      return this.$store.state.absenteeBids;
    },
    bids() {
      return this.$store.state.bids;
    },
    msgLotHasBisStart() {
      return this.$store.state.msgLotHasBisStart;
    },
    msgLotHasBisEnd() {
      return this.$store.state.msgLotHasBisEnd;
    },
    msgStatusLot() {
      return this.$store.state.msgStatusLot;
    },
    step() {
      return this.$store.state.step;
    },
    isVisitor() {
      return this.$store.state.isVisitor;
    },
    isAllowedBuying() {
      return this.$store.state.isAllowedBuying;
    },
    socketConnected() {
      return this.$store.state.socketConnected;
    },
    socketStep() {
      return this.$store.state.socketStep;
    },
    wocRunningState() {
      return this.$store.state.wocRunningState;
    },
  },

  created() {
    if (window.location.href.includes('/admin')) {
      this.$store.commit('changeIsAdmin', true);
      this.$store.commit('changeIsVisitor', false);
    }
  },

  methods: {
    buyThis() {
      if (!this.isVisitor) {
        if (this.isAllowedBuying) {
          this.$socket.emit('buy_this', {
            lot_id: this.currentLot.id,
            price: this.price,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
