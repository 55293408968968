import { createStore } from 'vuex';
import moment from 'moment';
import 'moment/locale/fr-ch';

export default createStore({
  state: {
    cid: 0,
    step: 0,
    price: 0,
    msgLotHasBisStart: 'sale_area.bids_start',
    msgLotHasBisEnd: 'sale_area.bids_end',
    msgStatusLot: false,
    isAdmin: false,
    isVisitor: false,
    isAllowedBuying: false,
    catalogLoaded: false,
    catalogId: Number,
    catalogNumber: String,
    catalogNames: {},
    endOfSaleTexts: {},
    catalogEndSentence: {},
    nbrLots: Number,
    lots: [],
    currentLot: {},
    absenteeBids: 0,
    bids: [],
    timingLot: new Date(0, 0, 0, 0, 0, 4),
    timingVac: new Date(0, 0, 0, 0, 0, 20),
    timingPrice: new Date(0, 0, 0, 0, 0, 5),
    bagheraTime: moment(),
    dateStart: moment(),
    socketConnected: false,
    socketStep: 'CONNECTING',
    pingStart: Date.now(),
    pingTime: 0,
    wocRunningState: 'RUNNING',
  },
  mutations: {
    changeIsVisitor(state, data) {
      state.isVisitor = data;
    },
    changeIsAdmin(state, data) {
      state.isAdmin = data;
    },
    changeCid(state, cid) {
      state.cid = cid;
    },
    set_step(state, data) {
      state.step = data;
    },
    resetTiming() {
      this.state.timingLot = new Date(0, 0, 0, 0, 0, 4);
      this.state.timingVac = new Date(0, 0, 0, 0, 0, 20);
      this.state.timingPrice = new Date(0, 0, 0, 0, 0, 5);
    },
    set_ping_start(state) {
      state.pingStart = Date.now();
    },
    SOCKET_connect(state) {
      console.log('CONNECTED');
      state.socketConnected = true;
    },
    SOCKET_disconnect(state) {
      console.log('DISCONNECTED');
      state.socketConnected = false;
      state.socketStep = 'DISCONNECTED';
    },
    SOCKET_connecting(state) {
      console.log('CONNECTING');
      state.socketConnected = false;
      state.socketStep = 'CONNECTING';
    },
    SOCKET_reconnecting(state) {
      console.log('RECONNECTING');
      state.socketConnected = false;
      state.socketStep = 'RECONNECTING';
    },
    SOCKET_reconnect_attempt(state) {
      console.log('RECONNECT ATTEMPT');
      state.socketConnected = false;
      state.socketStep = 'RECONNECT ATTEMPT';
    },
    SOCKET_reconnect(state) {
      console.log('RECONNECT');
      state.socketConnected = false;
      state.socketStep = 'RECONNECT';
    },
    SOCKET_pong_user(state) {
      state.pingTime = Date.now() - state.pingStart;
    },
    SOCKET_woc_running(state, data) {
      state.wocRunningState = data.woc_state;
    },
    SOCKET_allow_buying(state, data) {
      state.isAllowedBuying = !!data.allow;
    },
    SOCKET_set_prep_timing(state, data) {
      let minutes = Math.floor((data.timing % 3600) / 60);
      let seconds = Math.floor((data.timing % 3600) % 60);
      state.timingVac = new Date(0, 0, 0, 0, minutes, seconds);
    },
    SOCKET_set_level_timing(state, data) {
      state.timingPrice = new Date(0, 0, 0, 0, 0, data.timing);
    },
    SOCKET_set_pres_timing(state, data) {
      state.timingLot = new Date(0, 0, 0, 0, 0, data.timing);
    },
    SOCKET_set_catalog(state, data) {
      state.dateStart = moment(data.catalog.date_debut_vente);
      state.catalogNumber = data.catalog.numero_vente;
      state.catalogId = data.catalog.id;
      state.catalogNames = data.catalog.noms;
      state.endOfSaleTexts = data.catalog.text_fin_vente;
      state.lots = data.catalog.lots;
      state.nbrLots = data.catalog.lots.length;
      state.catalogLoaded = true;
    },
    SOCKET_reset(state) {
      state.bids = [];
    },
    SOCKET_set_lot(state, data) {
      state.currentLot = state.lots[data.index];
      state.absenteeBids = data.has_bids;
      state.price = false;
      state.msgStatusLot = false;
    },
    SOCKET_set_price(state, data) {
      state.price = data.price;
    },
    SOCKET_lost_lot(state) {
      state.msgStatusLot = 'sale_area.unsold';
    },
    SOCKET_share_buy(state, data) {
      if (data.buy === 1) {
        state.bids.push(data.lot);
        state.msgStatusLot = 'sale_area.bought';
      } else {
        state.msgStatusLot = 'sale_area.sold';
      }
    },
    SOCKET_set_bids(state, data) {
      state.bids = data.bids;
    },
    SOCKET_user_disconnected(state, data) {
      console.log('DISCONNECTED');
      state.isAllowedBuying = data.isAllowedBuying;
      state.isVisitor = data.isVisitor;
    },
  },
  actions: {
    SOCKET_set_step(state, data) {
      state.commit('set_step', data.step);
      state.commit('resetTiming');
    },
    SOCKET_end_buy(state) {
      state.commit('set_step', 4);
      state.commit('resetTiming');
    },
  },
});
