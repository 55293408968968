<template>
  <img :src="imageSrc" alt="Baghera/wines" class="img" />
</template>

<script>
import { imageUrl } from '../../config';

export default {
  name: 'LotPicture',
  computed: {
    imageSrc() {
      const imgOrigin = this.$store.state.currentLot.url_thumb;
      if (imgOrigin) {
        return imageUrl + '/' + imgOrigin;
      } else {
        return require(`@/assets/placeholder.png`);
      }
    },
  },
};
</script>
<style scoped></style>
