export const fr = {
  header: {
    about: 'À propos',
    blog: 'Blog',
    contact: 'Contact',
    account: 'Mon espace',
    help: 'Aide',
  },
  footer: {
    legal_info: 'Mentions légales',
    privacy: 'Confidentialité & cookies',
    time: 'Chez Baghera/wines, il est',
  },
  lobby: {
    wineOClock: "Wine O'clock",
    type: 'En ligne',
    sale: 'Vente',
    lots: 'lots',
    vac_wait: "Bienvenue sur la Wine O'Clock (WOC)",
    vac_start: "Une Wine O'Clock (WOC) va débuter dans ",
    vac_timer: '0 heures {minutes} minutes {seconds} secondes',
  },
  price_area: {
    timer_sec: 'sec',
    timer_text_level: '... Avant palier suivant',
    timer_text_lot: '... De présentation du lot',
    price_subtitle: '... actuellement',
    currency: 'CHF',
  },
  sale_area: {
    title: 'Catalogue',
    lot: 'LOT',
    buy_button: 'Acheter',
    bids_start: 'Attention, des ordres ont',
    bids_end: 'déjà été posés sur ce lot',
    unsold: 'invendu',
    bought: 'Achat enregistré',
    sold: 'Le lot a été acheté',
  },
  details_area: {
    title: 'le lot en détail...',
    catalog_title: 'le catalogue',
    catalog_text:
      'Si vous souhaitez consulter le catalogue en ligne alors que la vente au\n' +
      '        cadran est en cours, un nouvel onglet s’ouvrira afin de ne pas quitter\n' +
      '        la vente.',
    catalog_link: 'consulter le catalogue',
    wine_title: 'informations relatives au vin',
    wine_country: "Pays d'origine",
    wine_area: 'Région',
    wine_subdivision: 'Subdivision',
    wine_produceur: 'Producteur',
    wine_classification: 'Classement',
    wine_vintage: 'Millésime',
    wine_titling: 'Titrage',
    lot_title: 'informations relatives au lot',
    lot_type: 'Type',
    lot_format: 'Format',
    lot_quantity: 'Quantité',
    lot_colisage: 'Colisage',
    lot_nature: 'Nature',
    lot_capacity: 'Contenance',
    lot_condition: 'Etat',
    lot_comment: 'Commentaire',
  },
  lot_area: {
    lots: 'tous les lots',
    bids: 'mes lots remportés',
    bids_next: '(hors commission acheteur de 22% HT)',
  },
  sale_end: {
    first: "La Wine O'Clock (WOC) est terminée pour le catalogue",
    final: 'Merci de votre participation',
    greetings: 'See you soon !',
  },
  ping_area: {
    reactivite: 'Qualité de votre connexion :',
    level_bon: 'Bonne',
    level_moyen: 'Faible',
    level_mauvais: 'Mauvaise',
  },
};
