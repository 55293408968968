// PROD
export const baseUrl = 'https://woc.bagherawines.auction';
export const imageUrl = 'https://bagherawines.auction';
export const auctionUrl = 'https://www.bagherawines.auction/';
export const wordpressUrl = 'https://www.bagherawines.com/';
export const blogUrl = 'https://www.bagherawines-blog.com/';

// TEST
// export const baseUrl = 'https://woc-test.bagherawines.auction';
// export const imageUrl = 'https://test.bagherawines.auction';
// export const auctionUrl = 'https://test.bagherawines.auction/';
// export const wordpressUrl = 'https://test.bagherawines.com/';
// export const blogUrl = 'https://www.bagherawines-blog.com/';

// LOCAL
// export const baseUrl = 'http://localhost:3000';
// export const imageUrl = 'http://bagherawines.auction.local';
// export const auctionUrl = 'http://bagherawines.auction.local/';
// export const wordpressUrl = 'https://test.bagherawines.com/';
// export const blogUrl = 'https://www.bagherawines-blog.com/';