<template>
  <hr class="mb10" />
  <p class="text-center">
    <span class="current_seconde ng-binding">{{ timer }}</span>
    <span class="unit">{{ $t('price_area.timer_sec') }}</span
    ><br />
    <span class="precision">{{ title }}</span>
  </p>
  <hr class="mb30" />
  <hr class="mb10" v-if="price" />
  <p class="text-center" v-if="price">
    <span class="unit" v-if="price">{{ $t('price_area.currency') }}</span>
    <span class="current_montant" v-if="price">{{ price }}</span
    ><br />
    <span class="precision">{{ $t('price_area.price_subtitle') }}</span>
  </p>
  <hr class="mb10" v-if="price" />
</template>
<script>
export default {
  name: 'PriceArea',
  components: {},
  props: {
    timer: Number,
    title: String,
    price: Number,
  },
};
</script>
<style scoped></style>
