<template>
  <div class="row">
    <div class="col-12">
      <div class="presentation-vac">
        <ul class="infos text-uppercase">
          <li class="info">
            <img :src="require(`@/assets/clock.svg`)" alt="Baghera/wines" />
          </li>
          <li class="info">
            <span class="info-bold">
              {{ time }}
            </span>
          </li>
          <li class="info">{{ $t('lobby.type') }}</li>
          <li class="nowrap info">
            {{ $t('lobby.sale') }} #{{ catalogNumber }}
          </li>
          <li class="nowrap info">{{ nbrLots }} {{ $t('lobby.lots') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VacPresentation',
  computed: {
    catalogNumber() {
      return this.$store.state.catalogNumber;
    },
    nbrLots() {
      return this.$store.state.nbrLots;
    },
    time() {
      const time = this.$store.state.dateStart;

      let langDate = this.$i18n.locale === 'fr' ? 'fr' : 'en'; // Si chinois ou anglais alors on affiche la date en anglais

      return `${time.locale(langDate).format('LLL')}  ${
        this.$i18n.locale === 'fr'
          ? ''
          : `( UTC ${time.utcOffset() / 60 > 0 ? '+' : ''}${
              time.utcOffset() / 60
            } )`
      }`;
    },
  },
};
</script>

<style scoped lang="scss"></style>
