import VueSocketIO from 'vue-3-socket.io';
import io from 'socket.io-client';
import store from '../store';
import { baseUrl } from '../config';

export default async function socketInstance(app) {
  const jwt = sessionStorage.getItem('jwt');

  let options = { query: 'uid=' + jwt, withCredentials: false };
  const socket = io(baseUrl, options);
  app.config.globalProperties.$socket = socket;
  new VueSocketIO({
    debug: false,
    connection: socket,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
  });
  app.provide('socketInstance', socket);
}
