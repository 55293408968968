<template>
  <Header></Header>
  <div class="container main vac">
    <router-view />
  </div>
  <div class="ping-area">
    <p>
      {{ $t('ping_area.reactivite') }} {{ $t(`ping_area.${pingLevel}`) }} ({{
        pingTime
      }}
      ms)
    </p>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import router from './router';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {
    pingTime() {
      return this.$store.state.pingTime;
    },
    pingLevel() {
      if (this.pingTime > 150) {
        return 'level_mauvais';
      } else if (this.pingTime >= 100) {
        return 'level_moyen';
      } else {
        return 'level_bon';
      }
    },
  },

  methods: {
    ping() {
      this.$store.commit('set_ping_start', true);
      this.$socket.emit('ping_user');
    },
  },

  mounted() {
    setInterval(() => {
      this.ping();
    }, 2000);

    if (this.$store.state.wocRunningState == 'WAIT') {
      router.push({ name: 'Lobby' });
    } else {
      router.push({ name: 'Catalog' });
    }

    this.$watch(
      () => [this.$store.state.wocRunningState, this.$store.state.step],
      () => {
        if (
          (this.$store.state.wocRunningState == 'RUNNING' ||
            this.$store.state.wocRunningState == 'END') &&
          this.$store.state.step != 0
        ) {
          router.push({ name: 'Catalog' });
        } else {
          router.push({ name: 'Lobby' });
        }
      },
    );
  },
};
</script>

<style lang="scss">
@import url('assets/scss/global.scss');
@import url('assets/scss/_fonts.scss');
</style>
