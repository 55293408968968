<template>
  <div class="row btn-acheter-zone">
    <button :title="msg" class="btn-acheter">{{ msg }}</button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    msg: String,
  },
};
</script>

<style scoped></style>
