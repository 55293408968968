<template>
  <div class="paliers">
    <span
      v-for="price in prices"
      :key="price.index"
      class="palier"
      v-bind:class="{ current: price === currentPrice }"
      >{{ price }}</span
    >
  </div>
  <hr class="mb30" />
</template>
<script>
export default {
  name: 'PriceLanding',
  components: {},
  props: {
    prices: Array,
  },
  computed: {
    currentPrice() {
      return this.$store.state.price;
    },
  },
};
</script>
<style scoped></style>
