export const en = {
  header: {
    about: 'About us',
    blog: 'Blog',
    contact: 'Contact',
    account: 'My account',
    help: 'Help',
  },
  footer: {
    legal_info: 'Legal information',
    privacy: 'Privacy & cookies',
    time: "At Baghera/wines, it's",
  },
  lobby: {
    wineOClock: "Wine O'clock",
    type: 'Online',
    sale: 'Sale',
    lots: 'lots',
    vac_wait: "Welcome to the Wine O'Clock (WOC)",
    vac_start: 'A clock auction will start in ',
    vac_timer: '0 hours  {minutes} minutes {seconds} seconds',
  },
  price_area: {
    timer_sec: 'sec',
    timer_text_level: '... Before next bids step',
    timer_text_lot: '... Of presentation',
    price_subtitle: '... Currently',
    currency: 'CHF',
  },
  sale_area: {
    title: 'Catalog',
    lot: 'LOT',
    buy_button: 'Buy',
    bids_start: 'Please note that absentee bid(s)',
    bids_end: 'has been left on this lot',
    unsold: 'unsold',
    bought: 'Buy recorded',
    sold: 'The lot was purchased',
  },
  details_area: {
    title: 'Lot detail...',
    catalog_title: 'The catalogue',
    catalog_text:
      'If you want to view the catalogue as the auction clock is running, a new tab will open in order not to leave the sale',
    catalog_link: 'View catalogue',
    wine_title: 'Wine description',
    wine_country: 'Country of origin',
    wine_area: 'Area',
    wine_subdivision: 'Subdivision',
    wine_produceur: 'Producer',
    wine_classification: 'Classification',
    wine_vintage: 'Vintage',
    wine_titling: 'Alcool Content',
    lot_title: 'Lot informations',
    lot_type: 'Type',
    lot_format: 'Format',
    lot_colisage: 'Colisage',
    lot_quantity: 'Quantity',
    lot_nature: 'Nature',
    lot_capacity: 'Capacity',
    lot_condition: 'Condition',
    lot_comment: 'Comment',
  },
  lot_area: {
    lots: 'All lots',
    bids: 'My successfull bids',
    bids_next: "(EXCLUDING BUYER'S COMMISSION OF 22%)",
  },
  sale_end: {
    first: "Wine O'Clock is over for the catalog",
    final: 'Thank you for sharing this moment with us.',
    greetings: 'See you soon !',
  },
  ping_area: {
    reactivite: 'Connection quality:',
    level_bon: 'Good',
    level_moyen: 'Medium',
    level_mauvais: 'Bad',
  },
};
