<template>
  <div class="row">
    <div class="col-md-12">
      <h1 class="lot-details">{{ $t('details_area.title') }}</h1>
    </div>
  </div>
  <div class="detail-zone row">
    <div class="col-xs-12 col-md-4 col-lg-4 catalogue-area">
      <hr class="mb10" />
      <p class="entete">{{ $t('details_area.catalog_title') }}</p>
      <hr class="mb10" />
      <p class="note">
        {{ $t('details_area.catalog_text') }}
      </p>
      <p>
        <a
          :href="
            'https://www.bagherawines.auction/' +
            this.$i18n.locale +
            '/catalogue/voir/' +
            catalogId
          "
          target="_blank"
          class="btn-voir-catalogue"
          >{{ $t('details_area.catalog_link') }}</a
        >
      </p>
    </div>
    <div class="col-xs-12 col-md-8 col-lg-8">
      <div class="col-xs-12 col-md-12 col-lg-12 info-relative-lot">
        <hr class="mb10" />
        <p class="entete">{{ $t('details_area.wine_title') }}</p>
        <hr class="mb10" />
        <div class="col-xs-12 col-mb-12 col-lg-12 info-relative-lot-detail">
          <div class="col-xs-12 col-md-6 col-lg-6 detail-sub-area">
            <div
              class="information_line ng-scope"
              v-if="lot.pays_fr && this.$i18n.locale === 'fr'"
            >
              <div class="information_label">
                {{ $t('details_area.wine_country') }}
              </div>
              <div class="information_value ng-binding">{{ lot.pays_fr }}</div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.pays_cn && this.$i18n.locale === 'cn'"
            >
              <div class="information_label">
                {{ $t('details_area.wine_country') }}
              </div>
              <div class="information_value ng-binding">{{ lot.pays_cn }}</div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.pays_en && this.$i18n.locale === 'en'"
            >
              <div class="information_label">
                {{ $t('details_area.wine_country') }}
              </div>
              <div class="information_value ng-binding">{{ lot.pays_en }}</div>
            </div>

            <div
              class="information_line ng-scope"
              v-if="lot.region_fr && this.$i18n.locale === 'fr'"
            >
              <div class="information_label">
                {{ $t('details_area.wine_area') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.region_fr }}
              </div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.region_cn && this.$i18n.locale === 'cn'"
            >
              <div class="information_label">
                {{ $t('details_area.wine_area') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.region_cn }}
              </div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.region_en && this.$i18n.locale === 'en'"
            >
              <div class="information_label">
                {{ $t('details_area.wine_area') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.region_en }}
              </div>
            </div>
            <div class="information_line ng-scope" v-if="lot.subdivision">
              <div class="information_label">
                {{ $t('details_area.wine_subdivision') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.subdivision }}
              </div>
            </div>
            <div class="information_line ng-scope" v-if="lot.producteur">
              <div class="information_label">
                {{ $t('details_area.wine_produceur') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.producteur }}
              </div>
            </div>
            <div class="information_line ng-scope" v-if="lot.classement">
              <div class="information_label">
                {{ $t('details_area.wine_classification') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.classement }}
              </div>
            </div>
            <div class="information_line ng-scope" v-if="lot.millesime">
              <div class="information_label">
                {{ $t('details_area.wine_vintage') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.millesime }}
              </div>
            </div>
            <div class="information_line ng-scope" v-if="lot.titrage">
              <div class="information_label">
                {{ $t('details_area.wine_titling') }}
              </div>
              <div class="information_value ng-binding">{{ lot.titrage }}</div>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 col-lg-6 lot-detail-area">
            <div
              class="information_line ng-scope"
              v-if="lot.type_fr && this.$i18n.locale === 'fr'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_type') }}
              </div>
              <div class="information_value ng-binding">{{ lot.type_fr }}</div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.type_cn && this.$i18n.locale === 'cn'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_type') }}
              </div>
              <div class="information_value ng-binding">{{ lot.type_cn }}</div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.type_en && this.$i18n.locale === 'en'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_type') }}
              </div>
              <div class="information_value ng-binding">{{ lot.type_en }}</div>
            </div>

            <div
              class="information_line ng-scope"
              v-if="lot.format_fr && this.$i18n.locale === 'fr'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_format') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.format_fr }}
              </div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.format_cn && this.$i18n.locale === 'cn'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_format') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.format_cn }}
              </div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.format_en && this.$i18n.locale === 'en'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_format') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.format_en }}
              </div>
            </div>

            <div class="information_line ng-scope" v-if="lot.quantite">
              <div class="information_label">
                {{ $t('details_area.lot_quantity') }}
              </div>
              <div class="information_value ng-binding">{{ lot.quantite }}</div>
            </div>

            <div
              class="information_line ng-scope"
              v-if="lot.nature_fr && this.$i18n.locale === 'fr'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_nature') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.nature_fr }}
              </div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.nature_cn && this.$i18n.locale === 'cn'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_nature') }}
              </div>
              <div class="information_value">{{ lot.nature_cn }}</div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.nature_en && this.$i18n.locale === 'en'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_nature') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.nature_en }}
              </div>
            </div>

            <div class="information_line ng-scope" v-if="lot.contenance">
              <div class="information_label">
                {{ $t('details_area.lot_capacity') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.contenance }}
              </div>
            </div>

            <div
              class="information_line ng-scope"
              v-if="lot.colisage_fr && this.$i18n.locale === 'fr'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_colisage') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.colisage_fr }}
              </div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.colisage_cn && this.$i18n.locale === 'cn'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_colisage') }}
              </div>
              <div class="information_value">{{ lot.colisage_cn }}</div>
            </div>
            <div
              class="information_line ng-scope"
              v-if="lot.colisage_en && this.$i18n.locale === 'en'"
            >
              <div class="information_label">
                {{ $t('details_area.lot_colisage') }}
              </div>
              <div class="information_value ng-binding">
                {{ lot.colisage_en }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt20">
    <div class="col-lg-8 offset-lg-4 flex-column condition-and-comment">
      <div class="condition" v-if="lot.etat_fr && this.$i18n.locale === 'fr'">
        <hr class="mb10" />
        <div class="entete">
          {{ $t('details_area.lot_condition') }}
        </div>
        <hr class="mb10" />
        <div class="texte-classic">{{ lot.etat_fr }}</div>
      </div>
      <div class="condition" v-if="lot.etat_cn && this.$i18n.locale === 'cn'">
        <hr class="mb10" />
        <div class="entete">
          {{ $t('details_area.lot_condition') }}
        </div>
        <hr class="mb10" />
        <div class="texte-classic">{{ lot.etat_cn }}</div>
      </div>
      <div class="condition" v-if="lot.etat_en && this.$i18n.locale === 'en'">
        <hr class="mb10" />
        <div class="entete">
          {{ $t('details_area.lot_condition') }}
        </div>
        <hr class="mb10" />
        <div class="texte-classic">{{ lot.etat_en }}</div>
      </div>
      <div v-if="lot.commentaire_libre_fr && this.$i18n.locale === 'fr'">
        <hr class="mb10 mt30" />
        <div class="entete">
          {{ $t('details_area.lot_comment') }}
        </div>
        <hr class="mb10" />
        <div class="texte-classic">
          {{ lot.commentaire_libre_fr }}
        </div>
      </div>
      <div v-if="lot.commentaire_libre_cn && this.$i18n.locale === 'cn'">
        <hr class="mb10 mt30" />
        <div class="entete">
          {{ $t('details_area.lot_comment') }}
        </div>
        <hr class="mb10" />
        <div class="texte-classic">
          {{ lot.commentaire_libre_cn }}
        </div>
      </div>
      <div v-if="lot.commentaire_libre_en && this.$i18n.locale === 'en'">
        <hr class="mb10 mt30" />
        <div class="entete">
          {{ $t('details_area.lot_comment') }}
        </div>
        <hr class="mb10" />
        <div class="texte-classic">
          {{ lot.commentaire_libre_en }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsArea',
  props: {
    lot: Object,
  },
  computed: {
    catalogId() {
      return this.$store.state.catalogId;
    },
  },
};
</script>

<style scoped></style>
