export const cn = {
  header: {
    about: '簡介',
    blog: '博客',
    contact: '聯絡我們',
    account: '我的賬户',
    help: '常見問題',
  },
  footer: {
    legal_info: '法律信息',
    privacy: '隱私政策',
    time: '在 Baghera/wines，它是',
  },
  lobby: {
    wineOClock: '“美酒時刻 在線拍賣',
    type: '在線的',
    sale: '銷售',
    lots: '很多',
    vac_wait: "Welcome to the Wine O'Clock (WOC)",
    vac_start: '时钟拍卖将于 ',
    vac_timer: '0 小时 {minutes} 分 {seconds} 秒',
  },
  price_area: {
    timer_sec: '秒',
    timer_text_level: '... 在下一步進行之前',
    timer_text_lot: '... 推介會',
    price_subtitle: '... 現時',
    currency: 'CHF',
  },
  sale_area: {
    title: '目錄',
    lot: '很多',
    buy_button: '购买',
    bids_start: '请注意，',
    bids_end: '该拍品已留下缺席出价',
    unsold: '未售出',
    bought: '保存购买',
    sold: '该地段购买',
  },
  details_area: {
    title: '拍品細節',
    catalog_title: '拍賣圖錄',
    catalog_text:
      '倘若閣下想在拍賣進行途中瀏覽拍賣圖錄，懇請閣下打開新的一個網頁以防信息流失。',
    catalog_link: '拍賣圖錄',
    wine_title: '酒品描述',
    wine_country: '原產國',
    wine_area: '區域',
    wine_subdivision: '細分區域',
    wine_produceur: '酒莊',
    wine_classification: '等級',
    wine_vintage: '年份',
    wine_titling: '酒精含量',
    lot_title: '拍品信息',
    lot_type: '類別',
    lot_format: '瓶裝',
    lot_colisage: 'Colisage',
    lot_quantity: '數量',
    lot_nature: '名酒種類',
    lot_capacity: '容量',
    lot_condition: '狀態',
    lot_comment: '評論',
  },
  lot_area: {
    lots: '全部拍品',
    bids: '我已成功排得的拍品',
    bids_next: '(價格不包括22%的賣方佣金以及增值稅)',
  },
  sale_end: {
    first: "Wine O'Clock (WOC)",
    final: '感谢您的参与',
    greetings: '再见！',
  },
  ping_area: {
    reactivite: 'Connection quality:',
    level_bon: 'Good',
    level_moyen: 'Medium',
    level_mauvais: 'Bad',
  },
};
