<template>
  <div class="prep">
    <div class="desc" v-if="this.$store.state.wocRunningState == 'WAIT'">
      {{ $t('lobby.vac_start') }}
      <span>{{
        $t('lobby.vac_timer', {
          minutes: timing.minutes,
          seconds: timing.seconds,
        })
      }}</span>
    </div>
    <div class="desc" v-else>
      {{ $t('lobby.vac_wait') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    timing: Object,
  },
};
</script>
<style scoped></style>
