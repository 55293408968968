<template>
  <footer class="footer">
    <div class="logo">
      <a href="https://www.bagherawines.com">
        <img
          :src="require(`@/assets/LogoBagherawinesBlanc.svg`)"
          alt="Baghera/wines"
        />
      </a>
    </div>
    <div class="container-footer">
      <nav class="sub-footer">
        <div class="sub-footer__item">
          <a
            :href="
              'https://www.bagherawines.com/' + $i18n.locale + '/terms-of-use/'
            "
            >{{ $t('footer.legal_info') }}</a
          >
        </div>
        <div class="sub-footer__item">
          <a
            :href="
              'https://www.bagherawines.com/' +
              $i18n.locale +
              '/privacy-policy/'
            "
            >{{ $t('footer.privacy') }}</a
          >
        </div>
        <div class="sub-footer__item">
          {{ $t('footer.time') }}&nbsp;<span class="time">{{ time }}</span>
        </div>
        <div class="sub-footer__item sub-footer__item__last">
          &copy; 2022 BAGHERA WINES
        </div>
      </nav>
    </div>
    <div id="back-to-top">
      <a href="#top">
        <fa icon="arrow-up" id="arrow" />
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  computed: {
    time() {
      return this.$store.state.bagheraTime.format('LT');
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('../../assets/scss/footer.css');
</style>
