import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import socketInstance from './plugins/socketInstance';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from './store';
import { i18n } from './translation/translation';

const currentUrl = window.location.search;
const jwt = new URLSearchParams(currentUrl).get('jwt');
if (jwt) {
  sessionStorage.setItem('jwt', jwt);
  window.location = window.location.pathname;
}

library.add(fas);
createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(socketInstance)
  .component('fa', FontAwesomeIcon)
  .mount('#app');
