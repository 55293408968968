<template>
  <CountDown :timing="timing"></CountDown>
</template>

<script>
import router from '../router';
import CountDown from '@/components/CountDown/CountDown.vue';

export default {
  name: 'ResetCatalog',
  components: {
    CountDown,
  },
  computed: {
    timing() {
      if (
        this.$store.state.timingVac.getMinutes() === 0 &&
        this.$store.state.timingVac.getSeconds() === 0
      ) {
        router.push({ name: 'Catalog' });
      }
      return {
        minutes: this.$store.state.timingVac.getMinutes(),
        seconds: this.$store.state.timingVac.getSeconds(),
      };
    },
  },
};
</script>
