<template>
  <header class="header" id="main-header">
    <div class="header-brand" :class="{ open: showMobileMenu }">
      <div class="container">
        <button
          class="header-toggler"
          type="button"
          id="toggle-menu"
          :class="{ open: showMobileMenu }"
          @click="showMobileMenu = !showMobileMenu"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="17px"
            height="20px"
            viewBox="0 0 17 20"
            enable-background="new 0 0 17 20"
            xml:space="preserve"
            :class="{ open: showMobileMenu }"
          >
            <rect y="1" fill="#FFFFFF" width="17" height="1" />
            <rect y="7" fill="#FFFFFF" width="17" height="1" />
            <rect y="13" fill="#FFFFFF" width="17" height="1" />
          </svg>
          <span class="close d-none" :class="{ open: showMobileMenu }"></span>
        </button>
        <div class="header-logo" :class="{ open: showMobileMenu }">
          <a :href="wordpressUrl" class="header-logo__link"> Baghera/wines </a>
          <div class="header-logo__slogan" :class="{ open: showMobileMenu }">
            LEADING EUROPEAN FINE WINE AUCTIONEER • GENEVA
          </div>
        </div>
      </div>
    </div>
    <nav
      class="navbar d-none d-md-block"
      id="navbar-cross"
      :class="{ open: showMobileMenu }"
    >
      <div class="container">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              :href="wordpressUrl + $i18n.locale + '/about-us'"
              >{{ $t('header.about') }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="blogUrl">{{ $t('header.blog') }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :href="wordpressUrl + $i18n.locale + '/contact'"
              >{{ $t('header.contact') }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :href="auctionUrl + $i18n.locale + '/client/account/'"
              >{{ $t('header.account') }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :href="wordpressUrl + $i18n.locale + '/buy-and-sell'"
              >{{ $t('header.help') }}</a
            >
          </li>
        </ul>
      </div>
      <div>
        <p class="header-title">WINE O'CLOCK</p>
      </div>
    </nav>
    <div
      class="header-lang d-none d-md-block"
      :class="{ open: showMobileMenu }"
    >
      <ul>
        <li
          v-for="locale in $i18n.availableLocales.reverse()"
          :key="`locale-${locale}`"
          :value="locale"
        >
          <a
            @click="changeLang(locale)"
            v-bind:class="{ active: locale === $i18n.locale }"
            href="#"
          >
            {{ locale.toUpperCase() }}</a
          >
        </li>
      </ul>
    </div>
  </header>
</template>
<script>
import { auctionUrl, wordpressUrl, blogUrl } from '../../config';

export default {
  name: 'Header',
  components: {},
  data() {
    return {
      showMobileMenu: false,
      auctionUrl,
      wordpressUrl,
      blogUrl,
    };
  },
  methods: {
    changeLang(locale) {
      this.$i18n.locale = locale;
      console.log(this.$i18n.locale);
    },
  },
};
</script>
<style scoped>
@import url('../../assets/scss/header.css');
</style>
