<template>
  <div class="row flex">
    <div class="col-xs-12 col-md-6 col-lg-12 lot-responsive">
      <hr />
      <p class="entete">{{ $t('lot_area.lots') }}</p>
      <hr class="mb10" />
      <div class="all-lots">
        <table class="lots_display" id="table">
          <tbody>
            <tr
              v-for="lot in lots"
              :key="lot.index"
              v-bind:class="{ on: lot.lot === currentLotId }"
              v-bind:id="lot.lot"
            >
              <td class="num">{{ lot.lot }}</td>
              <td class="appellation texte-classic">{{ lot.appellation }}</td>
              <td class="prix">
                <span v-if="lot.winBid"
                  >{{ $t('price_area.currency') }} {{ lot.winBid.price }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-12 lot-win-responsive">
      <hr />
      <p class="entete">
        {{ $t('lot_area.bids') }} <br />
        {{ $t('lot_area.bids_next') }}
      </p>
      <hr class="mb10" />
      <div class="lots-remportes">
        <table class="lots_display">
          <tbody>
            <tr v-for="bid in bids" :key="bid.index">
              <td class="num">{{ bid.lot }}</td>
              <td class="appellation texte-classic">{{ bid.appellation }}</td>
              <td class="prix">
                {{ $t('price_area.currency') }} {{ bid.price }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LotArea',
  props: {
    lots: Array,
    currentLotId: Number,
    bids: Array,
  },
  updated() {
    this.scrollToElement();
  },
  methods: {
    scrollToElement() {
      if (this.currentLotId) {
        var el = document.getElementById(this.currentLotId);
        var table = document.getElementsByClassName('all-lots')[0];
        table.scroll({ top: el.offsetTop - 18, behavior: 'smooth' });
      }
    },
  },
};
</script>
<style scoped></style>
